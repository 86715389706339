import { setContext } from '@apollo/client/link/context';

let authorization: string;

export const setAuthorization = (value: string): void => {
  authorization = `Bearer ${value}`;
};

const authLink = setContext((_, { headers }) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  headers: {
    ...headers,
    authorization,
  },
}));

export default authLink;
