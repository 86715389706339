import { FC, lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import paths from '../../paths.json';

const Auth = lazy(() => import('../../routes/Auth'));
const Home = lazy(() => import('../../routes/Home'));
const Welcome = lazy(() => import('../../routes/Welcome'));
const Marketplace = lazy(() => import('../../routes/Marketplace'));

const App: FC = () => {
  const { pathname } = useLocation();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path={[paths.login, paths.changePassword, paths.resetPassword]}>
        <Welcome />
      </Route>
      <Route path={paths.auth}>
        <Auth />
      </Route>
      <Route path={paths.profiles}>
        <Marketplace />
      </Route>
      <Route path={[paths.home, paths.register, paths.thankYou]}>
        <Home />
      </Route>
    </Switch>
  );
};

App.displayName = 'App';

export default App;
