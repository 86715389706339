import { ErrorBoundary, withProfiler } from '@sentry/react';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import AntConfigProvider from '../AntConfigProvider';
import App from '../App';
import AuthenticationProvider from '../AuthenticationProvider';
import ConfigProvider from '../ConfigProvider';
import CookieSettingsProvider from '../CookieSettingsProvider';
import GoogleAnalytics from '../GoogleAnalytics';
import GraphQLProvider from '../GraphQLProvider/Marketplace';
import Hotjar from '../Hotjar';
import Loading from '../Loading';
import Sentry from '../Sentry';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import withException from '../withException';

import ErrorBoundaryFallback from './ErrorBoundaryFallback';

import './styles.less';

const Root = () => (
  <ErrorBoundary fallback={ErrorBoundaryFallback}>
    <BrowserRouter>
      <ConfigProvider>
        <CookieSettingsProvider>
          <Sentry />
          <GraphQLProvider>
            <Hotjar />
            <GoogleAnalytics />
            <AuthenticationProvider>
              <AntConfigProvider>
                <Suspense fallback={<Loading />}>
                  <App />
                </Suspense>
              </AntConfigProvider>
            </AuthenticationProvider>
          </GraphQLProvider>
        </CookieSettingsProvider>
      </ConfigProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

export default withProfiler(withException(Root));
