import { parse, ParsedQs, stringify } from 'qs';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const parseSearch = <T>(search: string) =>
  parse(search, {
    arrayLimit: Infinity, // qs otherwise parses array as an object if length > 20
    decoder(value, decoder) {
      const keywords: Record<string, false | true | null | undefined> = {
        false: false,
        null: null,
        true: true,
        undefined: undefined,
      };

      if (value in keywords) {
        return keywords[value];
      }

      try {
        return decoder(value);
      } catch (e) {
        return value;
      }
    },
    ignoreQueryPrefix: true,
    strictNullHandling: true,
  }) as unknown as T;

export const useSearch = <
  T extends Record<string, unknown> = ParsedQs,
>(): T => {
  const location = useLocation();
  const { search } = location;

  return useMemo(() => {
    return parseSearch<T>(search);
  }, [search]);
};

export const useUpdateSearch = (): ((
  query: Record<string, unknown>,
) => void) => {
  const history = useHistory();

  return useCallback(
    (query, { replace = false } = {}) => {
      const search = stringifyQuery({
        ...parseSearch(history.location.search),
        ...query,
      });

      if (replace) {
        history.replace({
          ...location,
          search,
        });
      } else {
        history.push({
          ...location,
          search,
        });
      }
    },
    [history],
  );
};

export const useSearchQuery = <
  T extends Record<string, unknown> = ParsedQs,
>(): {
  searchQuery: Partial<T>;
  updateSearchQuery: (query: Record<string, unknown>) => void;
} => {
  const searchQuery = useSearch<T>();

  const updateSearchQuery = useUpdateSearch();

  return useMemo(
    () => ({
      searchQuery,
      updateSearchQuery,
    }),
    [searchQuery, updateSearchQuery],
  );
};

export const stringifyQuery = (query: Record<string, unknown>): string =>
  stringify(query, {
    addQueryPrefix: true,
    strictNullHandling: true,
  });
